/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Components
import KmFilterRevamp from "./km-filter-revamp";
import YearFilterRevamp from "./year-filter-revamp";
import ModelFilterRevamp from "./model-filter-revamp";
import PriceFilterRevamp from "./price-filter-revamp";
import BodyTypeRevamp from "./body-type-revamp";
import ColorTypeRevamp from "./color-type-revamp";
import GenericTypeRevamp from "./generic-type-revamp";
import Filters from "../../../utils/filters-v2";
import DiscountType from "./discount-type";
import ComingSoonType from "./coming-soon";
import DoorsAndSeats from "./doors-and-seats";
import EngineFilter from "./engine-filter";
import OptionType from "./option-type";
import BookingFilter from "./booking-filter";
import LowImperfections from "./low-imperfections";
import Features from "./features-filter";
import Category from "./category-filter";
import TransmissionTypeRevamp from "./transmission-type-revamp";
import Cars24HubFilter from "./cars24-hub-filter";
import CarType from "./cars-type-filter";
import DownPaymentRevamp from "./downpayment-filter";

// Utilities
import { ALL_FILTERS_DESKTOP, ALL_FILTERS_DESKTOP_FOR_SALE, FILTER_ENTITIES, FILTER_TYPES } from "../../../utils/filters-v2/constants";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { DOWN_ARROW_ROTATE_DEGREE, NUMBER, UP_ARROW_ROTATE_DEGREE } from "../../../constants/app-constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import Accordion from "../../shared/accordion";
import ArrowIcon from "../../shared/arrow";
import FinanceFilterRevamp from "./finance-emi-filter";
import capitalize from "../../../utils/helpers/capitalize";
import { findByKey } from "../../../utils/helpers/find-by-key";
import { useHistory } from "react-router-dom";
import getUpdatedAppliedFilters, { getUpdatedSubFilterOptions } from "../../../utils/filters-v2/get-updated-applied-filters";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import DriveTypeFilter from "./drive-type-revamp";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

const filterMap = {
    [FILTER_ENTITIES.ODOMETER_READING]: {
        props: {},
        component: KmFilterRevamp
    },
    [FILTER_ENTITIES.DOWNPAYMENT]: {
        props: {},
        component: DownPaymentRevamp
    },
    [FILTER_ENTITIES.YEAR]: {
        props: {},
        component: YearFilterRevamp
    },
    [FILTER_ENTITIES.MAKE]: {
        props: {},
        component: ModelFilterRevamp
    },
    [FILTER_ENTITIES.PRICE]: {
        props: {},
        component: PriceFilterRevamp
    },
    [FILTER_ENTITIES.BODY_TYPE]: {
        props: {},
        component: BodyTypeRevamp
    },
    [FILTER_ENTITIES.COLOR]: {
        props: {},
        component: ColorTypeRevamp
    },
    [FILTER_ENTITIES.TRANSMISSION]: {
        props: {},
        component: TransmissionTypeRevamp
    },
    [FILTER_ENTITIES.FUEL_TYPE]: {
        props: {},
        component: GenericTypeRevamp
    },
    [FILTER_ENTITIES.FEATURES]: {
        props: {},
        component: Features
    },
    [FILTER_ENTITIES.CATEGORY]: {
        props: {},
        component: Category
    },
    [FILTER_ENTITIES.LOW_IMPERFECTION]: {
        props: {},
        component: LowImperfections
    },
    [FILTER_ENTITIES.DISCOUNT]: {
        props: {},
        component: DiscountType
    },
    [FILTER_ENTITIES.DRIVE_TYPE]: {
        props: {},
        component: DriveTypeFilter
    },
    [FILTER_ENTITIES.ENGINE]: {
        props: {},
        component: EngineFilter
    },
    [FILTER_ENTITIES.DOORS_SEATS]: {
        props: {},
        component: DoorsAndSeats
    },
    [FILTER_ENTITIES.OPTIONS_TYPE]: {
        props: {},
        component: OptionType
    },
    [FILTER_ENTITIES.BOOKED]: {
        props: {},
        component: BookingFilter
    },
    [FILTER_ENTITIES.CAR_TYPE]: {
        props: {},
        component: CarType
    },
    [FILTER_ENTITIES.FINANCE]: {
        props: {},
        component: FinanceFilterRevamp
    },
    [FILTER_ENTITIES.CARS24_HUB]: {
        props: {},
        component: Cars24HubFilter
    },
    [FILTER_ENTITIES.COMING_SOON]: {
        props: {},
        component: ComingSoonType
    }
};

const defaultOpenFiltersCount = NUMBER.FIVE;
const FilterRevamp = ({
    listingCount,
    selectedFilters,
    allFilters,
    selectedOnScreenFilter = [],
    selectedCityName,
    selectedCityCode,
    isListingCountLoading,
    selectedSuggestions,
    updateFilterOptionDataConnect,
    updateSelectedOnScreenFilterConnect,
    reloadCarListConnect,
    updateAllSubFilterOptionsConnect,
    location,
    updateSSRStatusConnect,
    isSSR,
    activeSaleConfig
}) => {
    const queryParams = parseLocationSearchParams(location.search);
    const { isCustomRecommended, wsAppId, search: searchQuery } = queryParams || {};
    const history = useHistory();

    const isSaleActive = activeSaleConfig?.active || false;
    const filterOrder = isSaleActive ? ALL_FILTERS_DESKTOP_FOR_SALE : ALL_FILTERS_DESKTOP;

    const selectedFilterKeyArr = filterOrder.filter((filter) =>
        findByKey(selectedFilters, "filterKey", filter)?.filterKey
    );
    const [SSRStatus, setSSRStatus] = useState(isSSR);

    useEffect(() => {
        if (listingCount === 0) {
            trackDesktopCustomEventsAE("filterNoCar", {
                eventLabel: selectedFilters.map((f) => f.filterKey === "quotedPrice" ? "Price" : f.filterKey).join("|")
            });
        }
    }, [listingCount, selectedFilters]);

    useEffect(() => {
        setSSRStatus(false);
    }, []);

    const createPathAndRedirect = (filtersToSelect) => {
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({
            selectedFilters: filtersToSelect,
            filters: allFilters,
            options: {
                ...(searchQuery && { search: searchQuery }),
                ...(isCustomRecommended && { isCustomRecommended: true }),
                ...(wsAppId && { wsAppId: true })
            }
        });
        history.push(relativeURL);
    };

    const onOptionSelection = async (data) => {
        await yieldToMainThread();
        if (data) {
            const { rangeValues, isRangeFilter, filterKey, optionKey, subOptionKey, filterType } = data;
            const { min, max } = rangeValues || {};
            let appliedFilter = {};
            if (filterType === FILTER_TYPES.GF) {
                const parentFilter = allFilters[filterKey];
                const { groupFacet } = parentFilter || {};
                appliedFilter = groupFacet[optionKey];
            } else {
                appliedFilter = allFilters[filterKey] || {};
            }
            const { max: maxRange, min: minRange } = appliedFilter;

            const isMinMaxUpdated = !(min === minRange && max === maxRange);
            if (isRangeFilter && !isMinMaxUpdated) {
                data = {
                    filterKey,
                    optionKey,
                    subOptionKey,
                    isSelected: false
                };
            }
            if (!SSRStatus) {
                // eslint-disable-next-line no-use-before-define
                handleUpdateSelectedFilters(data);
            }

        }
        scrollToTop();
        updateSSRStatusConnect(false);
        await yieldToMainThread();
        reloadCarListConnect(true);
    };

    const handleUpdateSelectedFilters = async (filterDetail) => {
        await yieldToMainThread();
        updateFilterOptionDataConnect(filterDetail);
        const filtersToSelect = getUpdatedAppliedFilters(filterDetail, selectedFilters);
        createPathAndRedirect(filtersToSelect);
    };

    const handleSubFilterUpdate = async ({ filterKey, optionKey, isSelected }) => {
        await yieldToMainThread();
        updateAllSubFilterOptionsConnect({
            filterKey, optionKey, isSelected
        });
        const selectedSubFilters = getUpdatedSubFilterOptions({ filterKey, optionKey, isSelected, selectedFilters, allFilters });
        createPathAndRedirect(selectedSubFilters);
        // await yieldToMainThread();
        // onOptionSelection();
    };

    const onFilterSelect = async (selectedFilter) => {
        const clickedFilterIndex = (selectedOnScreenFilter && selectedOnScreenFilter.findIndex(v => v === selectedFilter)) || -1;
        const clonedSelectedFilter = [...selectedOnScreenFilter];
        if (clickedFilterIndex !== -1) {
            clonedSelectedFilter.splice(clickedFilterIndex, 1);
        } else clonedSelectedFilter.push(selectedFilter);
        await yieldToMainThread();
        updateSelectedOnScreenFilterConnect([...(clonedSelectedFilter || [])]);
    };

    const handleFilterToggle = async (filterKey, idx, callback) => {
        if (idx <= defaultOpenFiltersCount) return;
        callback({ index: idx });
        await yieldToMainThread();
        onFilterSelect(filterKey);
    };
    const disableFilters = !isListingCountLoading && (!listingCount || listingCount === NUMBER.ZERO);
    return (
        <div styleName="styles.filterWrap" id="filterContainer">
            <div styleName={"styles.popupWrapper"}>
                <div styleName={"styles.filterOptionsWrapper"}>
                    <div styleName={"styles.leftWrapper"}>
                        <Accordion toggleAll={true} totalItems={filterOrder.length} visibleStateFlag={false}>
                            {({ onClick, currentVisibleStates }) => (
                                <React.Fragment>
                                    {(filterOrder || []).map((filter, filterOptionIndex) => {
                                        const SelectedComponent = filterMap[filter];
                                        const filterData = allFilters[filter];
                                        return filterData ? (
                                            <Accordion.Content key={filter}>
                                                <div styleName={`styles.contentWrap ${!selectedFilterKeyArr.includes(filter) && disableFilters ?
                                                    "styles.greyedFilter" : ""}`}>
                                                    <Accordion.Heading>
                                                        <div styleName={"styles.accordianHeading"} onClick={handleFilterToggle.bind(null, filterData?.key, filterOptionIndex, onClick)}>
                                                            <div styleName="styles.filterTitle"> {capitalize(filterData?.displayText)}</div>
                                                            {filterOptionIndex > defaultOpenFiltersCount && <ArrowIcon darkBlueDown={"darkBlueDown"} height={20} width={20} rotateBy={!currentVisibleStates[filterOptionIndex] ? UP_ARROW_ROTATE_DEGREE : DOWN_ARROW_ROTATE_DEGREE} />}
                                                        </div>
                                                    </Accordion.Heading>
                                                    <Accordion.Body>
                                                        {((currentVisibleStates[filterOptionIndex]) || (filterOptionIndex <= defaultOpenFiltersCount)) && (
                                                            <div styleName="styles.dataContainer">
                                                                {SelectedComponent && filterData?.key && <SelectedComponent.component {...SelectedComponent.props} onOptionSelection={onOptionSelection} handleSubFilterUpdate={handleSubFilterUpdate} handleUpdateSelectedFilters={handleUpdateSelectedFilters} appliedFilters={selectedFilters} filter={filterData} allFilters={allFilters} selectedSuggestions={selectedSuggestions} />}
                                                            </div>
                                                        )}
                                                    </Accordion.Body>
                                                </div>
                                            </Accordion.Content>
                                        )
                                            : null;
                                    })}
                                </React.Fragment>
                            )}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

FilterRevamp.propTypes = {
    listingCount: PropTypes.number,
    selectedFilters: PropTypes.array,
    selectedOnScreenFilter: PropTypes.string,
    allFilters: PropTypes.object,
    history: PropTypes.object,
    updateFilterOptionDataConnect: PropTypes.func,
    updateSelectedOnScreenFilterConnect: PropTypes.func,
    selectedCityName: PropTypes.string,
    selectedCityCode: PropTypes.string,
    selectedSuggestions: PropTypes.array,
    isListingCountLoading: PropTypes.bool,
    updateAppliedFiltersConnect: PropTypes.func,
    updateAppliedSuggestionsConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    location: PropTypes.object,
    urlOptions: PropTypes.object,
    setSearchResultTrackingDataConnect: PropTypes.func,
    updateAllSubFilterOptionsConnect: PropTypes.func,
    updateSSRStatusConnect: PropTypes.func,
    isSSR: PropTypes.bool,
    activeSaleConfig: PropTypes.object
};

export default FilterRevamp;
