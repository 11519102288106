import { TRANSACTION_TYPE, POST_BC_STEPS } from "../../../constants/ae/post-booking-constants";
import { BOOKING_TYPE, NUMBER } from "../../../constants/app-constants";
import { DeliveryService, CheckoutAEService as CheckoutService, CrmService, UaePaymentService } from "../../../service";
import Types from "./types";
import { STEP_NAMES } from "../post-bc-vas/constants";
import {
    setSelectedPlans, fetchVasConfigSuccess, fetchVasConfigFailure,
    fetchVasConfigInit, setBundleInfo, resetVasInfo
} from "../post-bc-vas/actions";
import { reducerConstants } from "../../../constants/reducer-constant";
import { INITIAL_STATE as postBCInitialState } from "../post-booking-confirmation-v2/reducers";

const resetPostBc = () => dispatch => {
    dispatch({ type: reducerConstants.POST_BC_DETAILS });
    dispatch({ type: reducerConstants.POST_BC_VAS });
};

const fetchOrderPostBcDetailsInit = () => ({
    type: Types.FETCH_ORDER_POSTBC_DETAILS_INIT
});

const fetchOrderPostBcDetailsSuccess = (data) => ({
    type: Types.FETCH_ORDER_POSTBC_DETAILS_SUCCESS,
    data
});

const fetchOrderPostBcDetailsFailure = (error) => ({
    type: Types.FETCH_ORDER_POSTBC_DETAILS_FAILURE,
    error
});

const initializeVas = (vasStep = {}, dispatch) => {

    // RESET VAS DATA
    dispatch(resetVasInfo());

    const warrantyPlans = (vasStep?.servicePlanList || []).filter(plan => plan?.type?.key === STEP_NAMES.WARRANTY) || {};
    const tintingPlan = (vasStep?.servicePlanList || []).find(plan => plan?.type?.key === STEP_NAMES.TINTING) || {};

    // SET BUNDLE
    dispatch(setBundleInfo({
        bundlePlan: {
            bundleName: vasStep?.bundle?.bundleName,
            servicePlanList: vasStep?.servicePlanList,
            bundleId: vasStep?.bundle?.bundleId
        },
        tintingDarkness: tintingPlan?.variables?.tintingDarkness
    }));

    // SET WARRANTY
    if (warrantyPlans.length > NUMBER.ONE) {
        const paidWarranty = warrantyPlans.find(plan => plan.offerPrice !== NUMBER.ZERO);
        const baseWarranty = warrantyPlans.find(plan => plan.offerPrice === NUMBER.ZERO);
        dispatch(setSelectedPlans({ [STEP_NAMES.WARRANTY]: paidWarranty, WARRANTY_BASE: baseWarranty }));
    } else if (warrantyPlans.length === NUMBER.ONE) {
        dispatch(setSelectedPlans({ [STEP_NAMES.WARRANTY]: warrantyPlans[NUMBER.ZERO], WARRANTY_BASE: warrantyPlans[NUMBER.ZERO] }));
    }

    (vasStep.servicePlanList || []).map((plan) => {
        // SET COATING
        if (plan.type.key === STEP_NAMES.COATING) {
            dispatch(setSelectedPlans({ [STEP_NAMES.COATING]: plan }));
        }

        // SET TINTING
        if (plan.type.key === STEP_NAMES.TINTING) {
            const planData = {
                ...plan,
                extraInfo: {
                    ...plan.extraInfo,
                    tintingDarkness: plan?.variables?.tintingDarkness,
                    windScreen: plan?.extraCharges?.windScreen?.offerPrice || NUMBER.ZERO
                }
            };
            dispatch(setSelectedPlans({ [STEP_NAMES.TINTING]: planData }));
        }

        // SET SERVICE_CONTRACT
        if (plan.type.key === STEP_NAMES.SERVICE_CONTRACT) {
            dispatch(setSelectedPlans({ [STEP_NAMES.SERVICE_CONTRACT]: plan }));
        }

        // SET INSURANCE
        if (plan.type.key === STEP_NAMES.INSURANCE) {
            dispatch(setSelectedPlans({ [STEP_NAMES.INSURANCE]: plan }));
        }

        // SET GAP_INSURANCE
        if (plan.type.key === STEP_NAMES.GAP_INSURANCE) {
            dispatch(setSelectedPlans({ [STEP_NAMES.GAP_INSURANCE]: plan }));
        }

        // SET ILOE INSURANCE
        if (plan.type.key === STEP_NAMES.ILOE) {
            dispatch(setSelectedPlans({ [STEP_NAMES.ILOE]: plan }));
        }

        // SET REFURB AND WARRANTY
        if (plan.type.key === STEP_NAMES.REFURB_AND_WARRANTY) {
            dispatch(setSelectedPlans({ [STEP_NAMES.REFURB_AND_WARRANTY]: plan }));
        }
    });
};

// RESET_ACTIVE_STEP_DATA

// eslint-disable-next-line max-params
const fetchOrderPostBcDetailsV2 = (appId, token, poll = false, config = { withLoader: true }) => (dispatch) => {
    if (config.withLoader) dispatch(fetchOrderPostBcDetailsInit());
    return new Promise((resolve, reject) => {
        DeliveryService.fetchPostBcStepsDataV2(appId, token, poll).then((response) => {
            const paymentObj = response?.data?.steps.find(itr => itr.stepName === TRANSACTION_TYPE.TOKEN_PAYMENT) || {};
            const orderDetails = { ...response.data, paymentMode: paymentObj.paymentMode };
            const { bookingType, bookingAmount } = response?.data?.order || {};
            const vasStep = response?.data?.steps.find(itr => itr.stepType === POST_BC_STEPS.vas) || {};

            // Check and set if MRL
            dispatch({
                type: Types.SET_IS_MRL,
                isMRL: bookingType === BOOKING_TYPE.MRL && bookingAmount === NUMBER.ZERO
            });

            initializeVas(vasStep, dispatch);

            dispatch(fetchOrderPostBcDetailsSuccess(orderDetails));
            resolve(response.data);
        }).catch((error) => {
            initializeVas({}, dispatch);
            dispatch(fetchOrderPostBcDetailsFailure(error));
            reject(error);
        });
    });
};

const fetchOrderCancellationReasons = (orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.fetchOrderCancelReasons(orderId, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const sendOrderCancellationReason = (orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.sendOrderCancellationReason(orderId, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateEmailIdPostBc = (emailId, token) => () => {
    return new Promise((resolve, reject) => {
        DeliveryService.updateEmail(emailId, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const generatePaymentLinkInit = () => ({
    type: Types.GENERATE_PAYMENT_LINK_INIT
});

const generatePaymentLinkSuccess = (data) => ({
    type: Types.GENERATE_PAYMENT_LINK_SUCCESS,
    data
});

const generatePaymentLinkFailure = (error) => ({
    type: Types.GENERATE_PAYMENT_LINK_FAILURE,
    error
});

const generatePaymentLink = (orderId, token, payload = {}) => (dispatch) => {
    dispatch(generatePaymentLinkInit());
    return new Promise((resolve, reject) => {
        DeliveryService.generatePaymentLink(orderId, token, payload).then((response) => {
            dispatch(generatePaymentLinkSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(generatePaymentLinkFailure(error));
            reject(error);
        });
    });
};

const generateTokenPaymentLink = (orderId, token, payload = {}) => (dispatch) => {
    dispatch(generatePaymentLinkInit());
    return new Promise((resolve, reject) => {
        DeliveryService.generateTokenPaymentLink(orderId, token, payload).then((response) => {
            dispatch(generatePaymentLinkSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(generatePaymentLinkFailure(error));
            reject(error);
        });
    });
};

const getTdNpsFeedbackInfo = (token) => () => {
    return new Promise((resolve, reject) => {
        DeliveryService.getTdNpsFeedbackInfo(token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateDbrDetailsInit = () => ({
    type: Types.UPDATE_DBR_DETAILS_INIT
});

const updateDbrDetailsSuccess = (data) => ({
    type: Types.UPDATE_DBR_DETAILS_SUCCESS,
    data
});

const updateDbrDetailsFailure = (error) => ({
    type: Types.UPDATE_DBR_DETAILS_FAILURE,
    error
});

const updateDbrDetails = (orderId, token, payload = {}) => (dispatch) => {
    dispatch(updateDbrDetailsInit());
    return new Promise((resolve, reject) => {
        DeliveryService.updateDbrDetails(orderId, token, payload).then((response) => {
            dispatch(updateDbrDetailsSuccess(response?.data || {}));
            resolve(response.data);
        }).catch((error) => {
            dispatch(updateDbrDetailsFailure(error));
            reject(error);
        });
    });
};

const updateCBDPaymentStatus = (payload = {}) => (_, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        UaePaymentService.putCBDPaymentStatus(payload, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const fetchReturnRepairReason = (retunRepairText, token) => () => {
    return new Promise((resolve, reject) => {
        CrmService.fetchReturnRepairReason(retunRepairText, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const postReturnRepairReason = (mobile, token, payload) => () => {
    const { orderId } = payload;
    return new Promise((resolve, reject) => {
        CrmService.postReturnRepairReason(mobile, orderId, payload, token).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setIsMRL = () => (dispatch, getState) => {
    const { postBcDetails: { order } = postBCInitialState } = getState();
    const { bookingType, bookingAmount } = order || {};
    dispatch({
        type: Types.SET_IS_MRL,
        isMRL: bookingType === BOOKING_TYPE.MRL && bookingAmount === NUMBER.ZERO
    });
};

const setIsDeliveryPickUp = () => (dispatch, getState) => {
    const { postBcDetails: { activeStepData: deliveryProps = {} } = postBCInitialState } = getState();
    dispatch({
        type: Types.SET_IS_DELIVERY_PICKUP,
        isDeliveryPickUp: deliveryProps.deliveryMode === "PICK_UP"
    });
};
const setIsFileUploadPageActive = (isFileUploadPageActive) => (dispatch) => {
    dispatch({
        type: Types.SET_FILE_UPLOAD_PAGE_ACTIVE,
        isFileUploadPageActive
    });
};
const setProgressLoader = (showLoader) => (dispatch) => {
    dispatch({
        type: Types.SHOW_PROGRESS_LOADER,
        showLoader
    });
};

const getChargeConfig = (orderId, token) => (dispatch) => {
    dispatch({ type: Types.FETCH_CHARGE_CONFIG_INIT });
    return new Promise((resolve, reject) => {
        DeliveryService.getChargeConfig(orderId, token).then((response) => {
            dispatch({ type: Types.FETCH_CHARGE_CONFIG_SUCCESS, data: response.data });
            resolve(response.data);
        }).catch((error) => {
            dispatch({ type: Types.FETCH_CHARGE_CONFIG_FAILURE, error });
            reject(error);
        });
    });
};

const fetchPostBookingConfigSuccess = (data) => ({
    type: Types.FETCH_POST_BC_CONFIG_SUCCESS,
    data
});

const fetchPostBookingConfigFailure = (error) => ({
    type: Types.FETCH_POST_BC_CONFIG_FAILURE,
    error
});

const fetchPostBookingConfig = (params) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    dispatch(fetchVasConfigInit());
    return new Promise((_, reject) => {
        DeliveryService.getVasConfig(secureToken, params)
            .then((response) => {
                dispatch(fetchPostBookingConfigSuccess(response?.data));
                dispatch(fetchVasConfigSuccess(response?.data?.vas));
            }).catch((err) => {
                dispatch(fetchPostBookingConfigFailure(err));
                dispatch(fetchVasConfigFailure(err));
                reject(err);
            });
    });
};

const toggleFinancingDocumentPopup = () => (dispatch) => {
    dispatch({
        type: Types.SET_FINANCING_DOCUMENT_POPUP
    });
};

const toggleVasDetailInfoPopup = () => (dispatch) => {
    dispatch({
        type: Types.SET_VAS_DETAIL_INFO_POPUP
    });
};

const setScheduleTestDriveShowMoreCTA = (isSelected) => {
    return {
        type: Types.SET_SCHEDULE_TEST_DRIVE_SHOW_MORE_CTA,
        isSelected
    };
};

const setShowTokenPaymentStepper = (isShow) => {
    return {
        type: Types.SET_IS_SHOW_TOKEN_PAYMENT_STEPPER,
        isShow
    };
};

const initiateBookingChangePayment = ({ orderId, payload }) => (_dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateBookingChangePayment({ orderId, payload, token: secureToken }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const generateInsuracePaymentLink = (orderId, payload = {}) => (_dispatch, getState) => {
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        DeliveryService.generateInsuracePaymentLink(orderId, payload, secureToken)
            .then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
};

const setShowProcessGuide = (showProcessGuide) => (dispatch) => {
    dispatch({
        type: Types.SET_SHOW_PROCESS_GUIDE,
        showProcessGuide
    });
};

const fetchCheckNpsStatusSuccess = (data) => ({
    type: Types.FETCH_NPS_STATUS_SUCCESS,
    data
});

const checkNpsStatus = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        DeliveryService.checkNpsStatus(params)
            .then((response) => {
                dispatch(fetchCheckNpsStatusSuccess(response.data));
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
};

const fetchClientNPSQuestionsSuccess = (data) => ({
    type: Types.FETCH_NPS_QUESTIONS_SUCCESS,
    data
});

const fetchClientNPSQuestions = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CrmService.getClientNPSQuestions(params)
            .then((response) => {
                dispatch(fetchClientNPSQuestionsSuccess(response.data));
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
};

const submitNPSFeedback = (payload, { orderId, userId }) => (_, getState) => {
    const { user: { mobile } } = getState();
    return new Promise((resolve, reject) => {
        CrmService.postClientNPSFeedback(payload, { orderId, userId: (userId || mobile) })
            .then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
};

const fetchClientNPSDirtyFeedbackSuccess = (data) => ({
    type: Types.FETCH_NPS_DIRTY_FEEDBACK,
    data
});

const fetchClientNPSDirtyFeedback = ({ orderId, feedbackSubCategory }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CrmService.getClientNPSDirtyFeedback({ orderId, feedbackSubCategory })
            .then((response) => {
                dispatch(fetchClientNPSDirtyFeedbackSuccess(response.data));
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
    });
};

const fetchActiveStepDataInit = () => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_INIT
});

const fetchActiveStepDataSuccess = (data) => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_SUCCESS,
    data
});

export const updateActiveStepDataForDocuments = (data) => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_FOR_DOCUMENTS,
    data
});

const fetchActiveStepDataFailure = (error) => ({
    type: Types.FETCH_ACTIVE_STEP_DATA_FAILURE,
    error
});

const fetchActiveStepData = ({ orderId, stepType, withLoader = true }) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();

    if (withLoader) dispatch(fetchActiveStepDataInit());
    return new Promise((resolve, reject) => {
        DeliveryService.getPostBCActiveStepData({ orderId, stepType, token: secureToken })
            .then((response) => {
                dispatch(fetchActiveStepDataSuccess(response.data));
                resolve(response.data);
                if (response?.data?.vasPlan) {
                    initializeVas(response?.data?.vasPlan, dispatch);
                }
            })
            .catch((error) => {
                dispatch(fetchActiveStepDataFailure());
                reject(error);
            });
    });
};

const resetActiveStepData = () => (dispatch) => {
    dispatch({ type: Types.RESET_ACTIVE_STEP_DATA });
};

const fetchTdAllActiveBookingsInit = () => ({
    type: Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_INIT
});

const fetchTdAllActiveBookingsSuccess = (data) => ({
    type: Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_SUCCESS,
    data
});

const fetchTdAllActiveBookingsFailure = () => ({
    type: Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_FAILURE
});

const fetchTdAllActiveBooking = (mobile, secureToken) => (dispatch) => {
    dispatch(fetchTdAllActiveBookingsInit());
    return new Promise((resolve, reject) => {
        DeliveryService.fetchTdAllActiveBooking(mobile, secureToken)
            .then((response) => {
                const data = response?.data || [];
                dispatch(fetchTdAllActiveBookingsSuccess(data));
                resolve(data);
            })
            .catch((err) => {
                dispatch(fetchTdAllActiveBookingsFailure());
                reject(err);
            });
    });
};

const updateRefurbRequestInit = () => ({
    type: Types.UPDATE_REFURB_REQUEST_INIT
});

const updateRefurbRequestSuccess = (data) => ({
    type: Types.UPDATE_REFURB_REQUEST_SUCCESS,
    data
});

const updateRefurbRequestFailure = (error) => ({
    type: Types.UPDATE_REFURB_REQUEST_FAILURE,
    error
});

const updateRefurbRequest = (orderId, params, token) => (dispatch) => {
    dispatch(updateRefurbRequestInit());
    return new Promise((resolve, reject) => {
        DeliveryService.updateRefurbRequest(orderId, params, token).then((response) => {
            dispatch(updateRefurbRequestSuccess(response?.data || {}));
            resolve(response.data);
        }).catch((error) => {
            dispatch(updateRefurbRequestFailure(error));
            reject(error);
        });
    });
};

const updateSlotDetailsTD = (bookedSlotDetail) => ({
    type: Types.UPDATE_SLOT_TD,
    bookedSlotDetail
});

export {
    fetchOrderPostBcDetailsV2,
    fetchOrderCancellationReasons,
    sendOrderCancellationReason,
    updateEmailIdPostBc,
    generatePaymentLink,
    updateDbrDetails,
    fetchReturnRepairReason,
    postReturnRepairReason,
    setProgressLoader,
    getChargeConfig,
    setIsMRL,
    resetPostBc,
    updateCBDPaymentStatus,
    fetchPostBookingConfig,
    initiateBookingChangePayment,
    toggleFinancingDocumentPopup,
    toggleVasDetailInfoPopup,
    setScheduleTestDriveShowMoreCTA,
    setShowTokenPaymentStepper,
    generateInsuracePaymentLink,
    initializeVas,
    setShowProcessGuide,
    setIsDeliveryPickUp,
    fetchTdAllActiveBooking,
    updateRefurbRequest,
    generateTokenPaymentLink,

    // NPS
    checkNpsStatus,
    submitNPSFeedback,
    fetchClientNPSQuestions,
    fetchClientNPSDirtyFeedback,
    getTdNpsFeedbackInfo,

    // Active Step Data
    resetActiveStepData,
    fetchActiveStepData,

    setIsFileUploadPageActive,
    updateSlotDetailsTD
};
