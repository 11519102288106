import { createReducer } from "../../../utils/redux/create-reducer";
import { NUMBER } from "../../../constants/app-constants";
import Types from "./types";

const CHARGE_CONFIG_INITIAL_STATE = {
    isLoading: false,
    data: null,
    error: null
};

const REFURB_REQUEST_INITIAL_STATE = {
    isLoading: false,
    data: null,
    error: null
};

const PAYMENT_LINK_INITIAL_STATE = {
    isLoading: false,
    data: null,
    error: null
};

const DBR_DETAILS_INITIAL_STATE = {
    isLoading: false,
    data: null,
    error: null
};

const NPS_CONFIG = {
    surveyEnabled: null,
    prefilledValues: null,
    surveyQuestions: null,
    isError: null
};

const ACTIVE_STEP_INITIAL_STATE = {
    isLoading: true,
    error: null
};

export const INITIAL_STATE = {
    orderId: null,
    isMRL: null,
    isDeliveryPickUp: null,
    isSaAutoVerified: null,
    salesAgreementLink: null,
    expiryDate: null,
    order: {},
    steps: [],
    isLoading: true,
    error: null,
    isFetched: false,
    showLoader: false,
    chargeConfig: CHARGE_CONFIG_INITIAL_STATE,
    postBcConfig: {
        homeDeliveryCharge: NUMBER.ZERO,
        documentationCharge: NUMBER.ZERO
    },
    isScheduleTestDriveShowMoreCTASelected: false,
    isShowTokenPaymentStepper: false,
    showProcessGuide: false,
    nps: NPS_CONFIG,
    activeStepData: ACTIVE_STEP_INITIAL_STATE,
    showFinancingDocumentPopup: false,
    showVasDetailInfoPopup: false,
    tdAllActiveBookings: [],
    isTdAllActiveBookingsLoading: true,
    refurbRequest: REFURB_REQUEST_INITIAL_STATE,
    paymentLink: PAYMENT_LINK_INITIAL_STATE,
    dbrDetails: DBR_DETAILS_INITIAL_STATE
};

const fetchOrderPostBcDetailsInit = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

const fetchOrderPostBcDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, error: null, isLoading: false, ...data, isFetched: true };
};

const fetchOrderPostBcDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, isLoading: false, error, isFetched: true };
};

const setIsMRL = (state = INITIAL_STATE, { isMRL }) => {
    return {
        ...state,
        isMRL
    };
};

const setIsDeliveryPickUp = (state = INITIAL_STATE, { isDeliveryPickUp }) => {
    return {
        ...state,
        isDeliveryPickUp
    };
};

const setIsFileUploadPageActive = (state = INITIAL_STATE, { isFileUploadPageActive }) => {
    return {
        ...state,
        isFileUploadPageActive
    };
};

export const showProgressLoader = (state = INITIAL_STATE, { showLoader }) => {
    return { ...state, showLoader };
};

const fetchChargeConfigInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        chargeConfig: {
            ...CHARGE_CONFIG_INITIAL_STATE,
            isLoading: true
        }
    };
};

const fetchChargeConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        chargeConfig: {
            ...CHARGE_CONFIG_INITIAL_STATE,
            isLoading: false,
            error: null,
            data
        }
    };
};

const fetchChargeConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        chargeConfig: {
            ...CHARGE_CONFIG_INITIAL_STATE,
            isLoading: false,
            error
        }
    };
};

const fetchPostBookingConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        postBcConfig: { ...data }
    };
};

const fetchPostBookingConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        postBcConfig: {
            ...state.postBcConfig,
            error
        }
    };
};

const setFinancingDocumentPopup = (state = INITIAL_STATE) => {
    return {
        ...state,
        showFinancingDocumentPopup: !state.showFinancingDocumentPopup
    };
};

const setVasDetailInfoPopup = (state = INITIAL_STATE) => {
    return {
        ...state,
        showVasDetailInfoPopup: !state.showVasDetailInfoPopup
    };
};

const setScheduleTestDriveShowMoreCTA = (state = INITIAL_STATE, { isSelected }) => {
    return {
        ...state,
        isScheduleTestDriveShowMoreCTASelected: isSelected
    };
};

const setShowTokenPaymentStepper = (state = INITIAL_STATE, { isShow }) => {
    return {
        ...state,
        isShowTokenPaymentStepper: isShow
    };
};

const setShowProcessGuide = (state = INITIAL_STATE, { showProcessGuide }) => {
    return { ...state, showProcessGuide };
};

const updateSlotDetailsTD = (state = INITIAL_STATE, { bookedSlotDetail }) => {
    const { address: tdAddress, from: tdSlotStart, to: tdSlotEnd, giftAvailable } = bookedSlotDetail;
    const oldTestDriveprops = (state?.steps || []).shift();
    const testDriveProps = { ...oldTestDriveprops, tdAddress, tdSlotStart, tdSlotEnd, giftAvailable };
    return {
        ...state,
        steps: [testDriveProps, ...state?.steps]
    };
};

const updateSlotDetailsDelivery = (state = INITIAL_STATE, { bookedSlotDetail }) => {
    const { address: deliveryAddress, from: deliverySlotStart, to: deliverySlotEnd } = bookedSlotDetail;
    const oldDeliveryProps = (state?.steps || []).pop();
    const deliveryProps = { ...oldDeliveryProps, deliveryAddress, deliverySlotStart, deliverySlotEnd };
    return {
        ...state,
        steps: [...(state?.steps || []), deliveryProps]
    };
};

const fetchCheckNpsStatusSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        nps: {
            ...state.nps,
            ...data,
            isFetched: true
        }
    };
};

const fetchClientNPSQuestionsSuccess = (state = INITIAL_STATE, { data }) => {
    const { feedback = [{}] } = data || {};
    return {
        ...state,
        nps: {
            ...state.nps,
            surveyQuestions: feedback[NUMBER.ZERO]
        }
    };
};

const fetchClientNPSDirtyFeedbackSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        nps: {
            ...state.nps,
            prefilledValues: data
        }
    };
};

const fetchActiveStepDataInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        activeStepData: {
            ...ACTIVE_STEP_INITIAL_STATE,
            isLoading: true
        }
    };
};

const fetchActiveStepDataSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        activeStepData: {
            ...ACTIVE_STEP_INITIAL_STATE,
            isLoading: false,
            error: null,
            ...data
        }
    };
};

const updateActiveSepDataForDocuments = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        activeStepData: {
            ...state.activeStepData,
            ...ACTIVE_STEP_INITIAL_STATE,
            isLoading: false,
            error: null,
            documents: data
        }
    };
};

const fetchActiveStepDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        activeStepData: {
            ...ACTIVE_STEP_INITIAL_STATE,
            isLoading: false,
            error
        }
    };
};

const resetActiveStepData = (state = INITIAL_STATE) => {
    return {
        ...state,
        activeStepData: ACTIVE_STEP_INITIAL_STATE
    };
};

const fetchTdAllActiveBookingsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        tdAllActiveBookings: [],
        isTdAllActiveBookingsLoading: true
    };
};

const fetchTdAllActiveBookingsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tdAllActiveBookings: data,
        isTdAllActiveBookingsLoading: false
    };
};

const fetchTdAllActiveBookingsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        tdAllActiveBookings: [],
        isTdAllActiveBookingsLoading: false
    };
};

const updateRefurbRequestInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        refurbRequest: {
            ...state?.refurbRequest,
            isLoading: true
        }
    };
};

const updateRefurbRequestSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        refurbRequest: {
            ...state?.refurbRequest,
            isLoading: false,
            error: null,
            data
        }
    };
};

const updateRefurbRequestFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        refurbRequest: {
            ...state?.refurbRequest,
            isLoading: false,
            error
        }
    };
};

const generatePaymentLinkInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        paymentLink: {
            ...PAYMENT_LINK_INITIAL_STATE,
            isLoading: true
        }
    };
};

const generatePaymentLinkSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        paymentLink: {
            ...PAYMENT_LINK_INITIAL_STATE,
            isLoading: false,
            data
        }
    };
};

const generatePaymentLinkFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        paymentLink: {
            ...PAYMENT_LINK_INITIAL_STATE,
            isLoading: false,
            error
        }
    };
};

const updateDbrDetailsInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        dbrDetails: {
            ...DBR_DETAILS_INITIAL_STATE,
            isLoading: true
        }
    };
};

const updateDbrDetailsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        dbrDetails: {
            ...DBR_DETAILS_INITIAL_STATE,
            isLoading: false,
            data
        }
    };
};

const updateDbrDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        dbrDetails: {
            ...DBR_DETAILS_INITIAL_STATE,
            isLoading: false,
            error
        }
    };
};

export const HANDLERS = {
    [Types.FETCH_ORDER_POSTBC_DETAILS_INIT]: fetchOrderPostBcDetailsInit,
    [Types.FETCH_ORDER_POSTBC_DETAILS_SUCCESS]: fetchOrderPostBcDetailsSuccess,
    [Types.FETCH_ORDER_POSTBC_DETAILS_FAILURE]: fetchOrderPostBcDetailsFailure,
    [Types.SET_IS_MRL]: setIsMRL,
    [Types.SET_IS_DELIVERY_PICKUP]: setIsDeliveryPickUp,
    [Types.SET_SHOW_PROCESS_GUIDE]: setShowProcessGuide,
    [Types.SHOW_PROGRESS_LOADER]: showProgressLoader,
    [Types.UPDATE_SLOT_TD]: updateSlotDetailsTD,
    [Types.UPDATE_SLOT_DL]: updateSlotDetailsDelivery,

    // Charge Config
    [Types.FETCH_CHARGE_CONFIG_INIT]: fetchChargeConfigInit,
    [Types.FETCH_CHARGE_CONFIG_SUCCESS]: fetchChargeConfigSuccess,
    [Types.FETCH_CHARGE_CONFIG_FAILURE]: fetchChargeConfigFailure,
    [Types.FETCH_POST_BC_CONFIG_SUCCESS]: fetchPostBookingConfigSuccess,
    [Types.FETCH_POST_BC_CONFIG_FAILURE]: fetchPostBookingConfigFailure,

    [Types.SET_FINANCING_DOCUMENT_POPUP]: setFinancingDocumentPopup,
    [Types.SET_VAS_DETAIL_INFO_POPUP]: setVasDetailInfoPopup,
    [Types.SET_SCHEDULE_TEST_DRIVE_SHOW_MORE_CTA]: setScheduleTestDriveShowMoreCTA,
    [Types.SET_IS_SHOW_TOKEN_PAYMENT_STEPPER]: setShowTokenPaymentStepper,

    // NPS
    [Types.FETCH_NPS_STATUS_SUCCESS]: fetchCheckNpsStatusSuccess,
    [Types.FETCH_NPS_QUESTIONS_SUCCESS]: fetchClientNPSQuestionsSuccess,
    [Types.FETCH_NPS_DIRTY_FEEDBACK]: fetchClientNPSDirtyFeedbackSuccess,

    [Types.FETCH_ACTIVE_STEP_DATA_INIT]: fetchActiveStepDataInit,
    [Types.FETCH_ACTIVE_STEP_DATA_SUCCESS]: fetchActiveStepDataSuccess,
    [Types.FETCH_ACTIVE_STEP_DATA_FOR_DOCUMENTS]: updateActiveSepDataForDocuments,
    [Types.FETCH_ACTIVE_STEP_DATA_FAILURE]: fetchActiveStepDataFailure,
    [Types.RESET_ACTIVE_STEP_DATA]: resetActiveStepData,

    [Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_INIT]: fetchTdAllActiveBookingsInit,
    [Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_SUCCESS]: fetchTdAllActiveBookingsSuccess,
    [Types.FETCH_TD_ALL_ACTIVE_BOOKINGS_FAILURE]: fetchTdAllActiveBookingsFailure,

    [Types.UPDATE_REFURB_REQUEST_INIT]: updateRefurbRequestInit,
    [Types.UPDATE_REFURB_REQUEST_SUCCESS]: updateRefurbRequestSuccess,
    [Types.UPDATE_REFURB_REQUEST_FAILURE]: updateRefurbRequestFailure,

    [Types.GENERATE_PAYMENT_LINK_INIT]: generatePaymentLinkInit,
    [Types.GENERATE_PAYMENT_LINK_SUCCESS]: generatePaymentLinkSuccess,
    [Types.GENERATE_PAYMENT_LINK_FAILURE]: generatePaymentLinkFailure,

    [Types.UPDATE_DBR_DETAILS_INIT]: updateDbrDetailsInit,
    [Types.UPDATE_DBR_DETAILS_SUCCESS]: updateDbrDetailsSuccess,
    [Types.UPDATE_DBR_DETAILS_FAILURE]: updateDbrDetailsFailure,

    [Types.SET_FILE_UPLOAD_PAGE_ACTIVE]: setIsFileUploadPageActive

};

export default createReducer(INITIAL_STATE, HANDLERS);
