/* eslint-disable complexity */
/* eslint-disable max-len */
import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { appUrl } from "../../../constants/url-constants";
import { NoCarImageFoundPath } from "../../ae.mobile/no-car-image-found";
import { getCarName } from "../../../utils/helpers/car-properties.ae";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { DEFAULT_AE_CITY_NAME } from "../../../constants/app-constants";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import getLowerCase from "../../../utils/helpers/get-lower-case";

// Utilities
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";

const MetaTagsCarDetailsV2 = ({
    match,
    location,
    carDetails = {},
    isNewCarPage = false,
    config = {},
    cityName = DEFAULT_AE_CITY_NAME,
    ratingInfo = {}
}) => {

    const { url } = match || {};
    const { mainImage = "", make = "", model = "", year = "", variant = "", threeSixtyViewUrl = "", odometerReading = "", listingActive = true } = carDetails || {};

    const detailsPageUrl = getLowerCase(`${appUrl()}${url.slice(-1) === "/" ? url : `${url}/`}`);
    const hasImage = mainImage && mainImage.path;
    const isView360Enabled = !!threeSixtyViewUrl;
    const imageUrl = hasImage ? fastlyUrl({
        basePath: config?.imageHost, path: mainImage.path,
        type: isView360Enabled ? imageTypes.gsCarCard360 : imageTypes.gsCarCard
    }) : NoCarImageFoundPath;
    const carName = `${getCarName(carDetails)} ${variant}`;
    const queryParams = parseLocationSearchParams(location.search);
    const isSeoPath = !Object.keys(queryParams)?.length;
    const isShowNoIndex = !isSeoPath || !listingActive || isNewCarPage;
    const { rating, ratingCount} = ratingInfo || {};

    const schema = JSON.stringify(
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": "1",
                    "name": "Home",
                    "item": `${appUrl()}/`
                },
                {
                    "@type": "ListItem",
                    "position": "2",
                    "name": "Used Cars in UAE",
                    "item": `${appUrl()}/buy-used-cars-uae/`
                },
                {
                    "@type": "ListItem",
                    "position": "3",
                    "name": `Used Cars in ${cityName || DEFAULT_AE_CITY_NAME}`,
                    "item": `${appUrl()}/buy-used-cars-${dashedLowercase(cityName)}/`
                },
                {
                    "@type": "ListItem",
                    "position": "4",
                    "name": `Used ${make} Cars in ${cityName || DEFAULT_AE_CITY_NAME}`,
                    "item": `${appUrl()}/buy-used-${dashedLowercase(make)}-cars-${dashedLowercase(cityName)}/`
                },
                {
                    "@type": "ListItem",
                    "position": "5",
                    "name": `Used ${model} Cars in ${cityName || DEFAULT_AE_CITY_NAME}`,
                    "item": `${appUrl()}/buy-used-${dashedLowercase(make)}-${dashedLowercase(model)}-cars-${dashedLowercase(cityName)}/`
                },
                {
                    "@type": "ListItem",
                    "position": "6",
                    "name": `Used ${getCarName(carDetails)} Cars in ${cityName || DEFAULT_AE_CITY_NAME}`
                }
            ]
        }
    );
    const aggregateRatingSchema = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Product",
        "name": isNewCarPage ? `New ${make} ${model} ${year} in UAE` : `Used ${carName} ${odometerReading} Kms Driven Car in ${cityName} |CARS24`,
        "brand": {
          "@type": "Brand",
          "name": "CARS24"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": rating,
          "reviewCount": ratingCount
        }
      });
    return (
        <Helmet script={[{
            type: "application/ld+json",
            innerHTML: schema
        },
        ...(rating ? [{
            type: "application/ld+json",
            innerHTML: aggregateRatingSchema
        }] : [])
        ]}>
            <title>{isNewCarPage ? `New ${make} ${model} ${year} in UAE` : `Used ${carName} ${odometerReading} Kms Driven Car in ${cityName} |CARS24`}</title>
            <meta name="ROBOTS" content={isShowNoIndex ? "NOINDEX,NOFOLLOW" : "INDEX,FOLLOW"} />
            <meta name="title" content={`Used ${carName} ${odometerReading} Kms Driven Car in ${cityName} - UAE | CARS24 UAE`} />
            <meta name="description"
                content={`${year} ${make} ${model} ${variant} with ${odometerReading} Kms on CARS24. Top-notch used car with 150+ checks, 7-day trial, and easy financing in UAE`} />
            <meta name="keywords"
                content={`Buy used ${carName}, buy second hand ${carName}, used ${carName} in ${cityName}, second hand ${carName} from ${cityName}, 2nd hand ${carName} online in ${cityName}, pre-owned ${carName} in ${cityName}`} />
            <meta property="og:title" content={`Used ${carName} in ${cityName} - Second Hand ${carName} in ${cityName} for Sale`} />
            <meta property="og:description"
                content={`${year} ${make} ${model} ${variant} with ${odometerReading} Kms on CARS24. Top-notch used car with 150+ checks, 7-day trial, and easy financing in UAE`} />
            <meta property="og:url" content={detailsPageUrl} />
            <link href={detailsPageUrl} rel="canonical" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Cars24" />
            {imageUrl && <meta property="og:image" content={imageUrl} />}
        </Helmet>
    );
};

MetaTagsCarDetailsV2.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    isNewCarPage: PropTypes.bool,
    cityName: PropTypes.string.isRequired,
    carDetails: PropTypes.object,
    config: PropTypes.object,
    ratingInfo: PropTypes.object
};

export default withRouter(MetaTagsCarDetailsV2);
