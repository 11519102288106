/* eslint-disable no-shadow */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

//Images
import BannerLogin from "./image/post-bc-login-banner.png";
import SaleBanner from "./image/sale.webp";
import IconWhatsApp from "./image/whatsapp-icon.svg";
import IconPerson from "./image/person.svg";
import IconWishlist from "./image/wishlist.svg";
import IconPattern from "./image/pattern.png";

// Components
import Button from "../../shared/button";
import MobileVerify from "./mobile-verify";
import CreateAccount from "./create-account";
import CheckoutCar from "../checkout-car";
import Checkbox from "../../shared/checkbox/component";
import CloseButton from "../../shared/icon-cross";

// constants
import { DEFAULT_LOGIN_TITLE, LOGIN_TITLE, NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS, COOKIE_CONSTANTS } from "../../../constants/app-constants";
import { CHANNEL_TYPES } from "../../../constants/api-constants";

// utils
import { mobileNumberValidatorAE as mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import nameValidator from "../../../utils/validators/name-validator";
import { validateEmail } from "../../../utils/validators/email-validator";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { withRouter } from "react-router-dom";
import gaId from "../../../utils/helpers/get-ga-from-cookie";
import capitalize from "../../../utils/helpers/capitalize";
import { SOURCES } from "./constants";
import saveCookie from "../../../utils/helpers/save-cookie";
import { parseSeasonalSaleData } from "../../../utils/helpers/parse-seasonal-sale";
import { appUrl } from "../../../constants/url-constants";

const STEPS = {
    LOGIN: "LOGIN",
    CREATE_ACCOUNT: "CREATE_ACCOUNT"
};

const BTN_TEXT = {
    [STEPS.LOGIN]: "VERIFY",
    [STEPS.CREATE_ACCOUNT]: "VERIFY"
};

const GA_EVENTS_INITIAL_STATE = {
    phone: false,
    otp: false,
    name: false,
    email: false
};

const validateOtp = (number) => {
    return number.length === NUMBER.FOUR;
};

const Login = ({
    mobile,
    name,
    email,
    isLoggedIn,
    sendOTPConnect,
    make,
    model,
    verifyOtpConnect,
    setProfileWithTokenConnect,
    updateProfileConnect,
    fetchUserConsentConnect,
    onSuccess = () => { },
    isVisible,
    isCheckoutFlow = false,
    checkout,
    match: { params: { lid: carId } },
    updateUserConsentConnect,
    userProfileConnect,
    isWAConsent,
    updateWAStatusConnect,
    updateUnlockCouponConnect,
    cityCode,
    lat,
    lng,
    loginFor,
    onClose,
    activeSaleConfig
}) => {
    const [userMobile, setUserMobile] = useState("");
    const [otp, setOtp] = useState("");
    const [activeStep, setActiveStep] = useState(STEPS.LOGIN);
    const initialWaStatus = useRef(null);
    const [disablePhone, setDisablePhone] = useState(isLoggedIn ? true : false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState();

    const mobileRef = useRef();
    const otpRef = useRef();

    const [accountDetails, setAccountDetails] = useState({ name, email });
    const [isLoading, setIsLoading] = useState(false);
    const [gaEvent, setGaEventSent] = useState(GA_EVENTS_INITIAL_STATE);
    const { order: { orderId = "" } = {} } = checkout || {};

    const { active: saleActive = false, saleDetails = {} } = activeSaleConfig || {};
    const {homePageBannerInfo = {}} = saleDetails || {};

    const parsedSaleData = useMemo(() => parseSeasonalSaleData(homePageBannerInfo), [homePageBannerInfo]) || {};
    const { title: loginTitle } = parsedSaleData || {};

    const { additionalData = {}} = homePageBannerInfo || {};
    const { imageUrl: loginBannerImageUrl = "", backgroundGradients } = additionalData || {};
    const loginBannerInlineStyles = backgroundGradients ? { backgroundImage: `linear-gradient(${backgroundGradients})` } : {};

    const focusPhone = () => {
        if (userMobile && userMobile.current) {
            window.setTimeout(() => {
                userMobile.current.focus();
            }, NUMBER.THREE_HUNDRED);
        }
    };

    useEffect(() => {
        setActiveStep(STEPS.LOGIN);
    }, []);

    useEffect(() => {
        if (!disablePhone && userMobile.length === NUMBER.TEN) {
            focusPhone();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disablePhone]);

    useEffect(() => {
        if (mobileNumberValidator(userMobile, true)) {
            const trimmedNumber = userMobile.indexOf("0") === 0 ? userMobile.replace(/^0+/, "") : userMobile;
            trackDesktopCustomEventsAE("buyUsedCarsEnterMobileNumber", {
                eventLabel: trimmedNumber
            });

            sendOTPConnect(trimmedNumber).then(() => {
                trackDesktopCustomEventsAE("buyUsedCarsLoginPageBI", {
                    eventLabel: "NA"
                });
                setShowOtpField(true);
                setShowTimer(true);
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMobile]);

    const handleMobile = (e) => {
        const { value } = e.target;
        setUserMobile(value);
        if (value.length === 1 && !gaEvent.phone) {
            trackDesktopCustomEventsAE("buyUsedCarsMobileNumber1Digit", { eventLabel: "BILogin" });
            setGaEventSent({ phone: true, ...gaEvent });
        }
    };

    const handleOtp = (number) => {
        setOtp(number);
        trackDesktopCustomEventsAE("buyUsedCarsOTPEntered", {
            eventLabel: "NA"
        });
        if (number.length === NUMBER.FOUR && !gaEvent.otp) {
            setGaEventSent({ otp: true, ...gaEvent });
        }
    };

    const resetErrorMessage = () => {
        setErrorMessage("");
    };

    const handleAccountDetails = (e) => {
        const { value, id: key } = e.target;
        setAccountDetails({ ...accountDetails, [key]: value });
        if (key === "name" && value.length === 1 && !gaEvent.name) {
            trackDesktopCustomEventsAE("b2cNameEntered");
            setGaEventSent({ name: true, ...gaEvent });
        }
        if (key === "email" && value.length === 1 && !gaEvent.email) {
            trackDesktopCustomEventsAE("b2cEmailEntered");
            setGaEventSent({ email: true, ...gaEvent });
        }
    };

    const triggerGA4LoginEvent = () => {
        trackDesktopCustomEventsAE("ga4Login", {
            user_id: userMobile,
            //is_new_user: IS_NEW_USER.SIGN_IN,
            user_status: USER_LOGIN_STATUS.LOGGED_IN,
            login_type: USER_LOGIN_MEDIUM.PHONE
        });
    };

    const handleUserLogin = (inputOtp) => {
        resetErrorMessage();
        setIsLoading(true);
        const params = {
            username: userMobile.indexOf("0") === 0 ? userMobile.replace(/^0+/, "") : userMobile,
            otp: inputOtp
        };

        return new Promise((resolve, reject) => {
            verifyOtpConnect(params).then(response => {
                trackDesktopCustomEventsAE("buyUsedCarsVerify", {
                    eventLabel: "success"
                });
                if (loginFor === SOURCES.SALE_BANNER_LISTING) {
                    trackDesktopCustomEventsAE("saleListingBannerLogin", { eventLabel: "sale_banner_listing"});
                } else if (loginFor === SOURCES.DP_SALE_LINE) {
                    trackDesktopCustomEventsAE("dpSaleLineLogin", { eventLabel: "dp_sale_line" });
                } else if (loginFor === SOURCES.MENU) {
                    trackDesktopCustomEventsAE("login", { eventLabel: "login/menu" });
                } else if (loginFor === SOURCES.MY_BOOKINGS) {
                    trackDesktopCustomEventsAE("login", { eventLabel: "login/My Bookings" });
                }
                setToken(response.access_token);
                setProfileWithTokenConnect(response.access_token, { updateDnd: true, isWhatsappOptIn: isWAConsent }).then((profileResponse) => {
                    const { mobilePhone, country, name } = profileResponse;
                    userProfileConnect(response.access_token, { uid: mobilePhone, country, phoneNumber: mobilePhone, userName: name, gaId, cityCode, lat, lng });
                    updateUnlockCouponConnect({
                        userId: mobilePhone,
                        ...(gaId && { gaid: gaId })
                    }, response.access_token);
                    setIsLoading(false);
                    triggerGA4LoginEvent();
                    // uncomment below for Sale
                    saveCookie(COOKIE_CONSTANTS.TOKEN, response.access_token);

                    // Update WhatsApp Notification Preference
                    if (typeof initialWaStatus.current === "boolean" && initialWaStatus.current !== isWAConsent) {
                        const consentPayload = {
                            uid: userMobile.indexOf("0") === 0 ? userMobile.replace(/^0+/, "") : userMobile,
                            optIn: isWAConsent,
                            channel: CHANNEL_TYPES.WHATSAPP
                        };
                        updateUserConsentConnect(consentPayload);
                    }

                    resolve({ ...profileResponse, token: response.access_token });
                });
            }).catch(error => {
                trackDesktopCustomEventsAE("buyUsedCarsVerify", {
                    eventLabel: "fail"
                });
                setToken();
                reject(error);
                setIsLoading(false);
                setErrorMessage("Invalid OTP. Please try again.");
            });
        });
    };

    const handleProfile = () => {
        setIsLoading(true);
        // eslint-disable-next-line no-shadow
        const { name, email } = accountDetails;
        updateProfileConnect(name, email, token).then(() => {
            trackDesktopCustomEventsAE("b2c_personal_detail_page_confirm", {
                booking_id: orderId,
                appointment_id: carId
            });
            setIsLoading(false);
            const cbProps = { name, email, token };
            onSuccess(cbProps);
        }).catch(() => {
            setIsLoading(false);
        });
    };

    const handleLogin = (value) => {
        if (validateOtp(value)) {
            setDisablePhone(true);
            handleUserLogin(value).then((response) => {
                const { name, email } = response;
                if ((name && email)) {
                    onSuccess(response);
                } else {
                    const trimmedNumber = userMobile.indexOf("0") === 0 ? userMobile.replace(/^0+/, "") : userMobile;
                    fetchUserConsentConnect({ uid: trimmedNumber }).then((consentDetail = {}) => {
                        initialWaStatus.current = consentDetail?.whatsapp;
                        setActiveStep(STEPS.CREATE_ACCOUNT);
                        updateWAStatusConnect(true);
                    }).finally(() => {
                        sendOTPConnect(trimmedNumber).then(() => {
                            trackDesktopCustomEventsAE("buyUsedCarsLoginPageBI", {
                                eventLabel: "NA"
                            });
                            setShowOtpField(true);
                            setShowTimer(true);
                        });
                    });

                }
            });
        }
    };

    const onResendClick = () => {
        trackDesktopCustomEventsAE("buyUsedCarsResendOTP", {
            eventLabel: "resend"
        });
        setShowTimer(true);
        sendOTPConnect(userMobile);
    };

    const onCompleteTimer = () => {
        setShowTimer(false);
    };

    const handler = (value) => {
        switch (activeStep) {
            case STEPS.LOGIN:
                handleLogin(value);
                break;
            case STEPS.CREATE_ACCOUNT:
                handleProfile();
                break;
            default:
                break;
        }
    };

    const onWhatsappToggle = () => {
        updateWAStatusConnect(!isWAConsent);
        // setIsWhatsappChecked(!isWAConsent);
    };

    const isBtnDisabled = () => {
        switch (activeStep) {
            case STEPS.LOGIN: {
                return validateOtp(otp) && mobileNumberValidator(userMobile, true) ? false : true;
            }
            case STEPS.CREATE_ACCOUNT: {
                return false;
            }
            default: return true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const mobileVerifyProps = {
        carName: capitalize(`${make} ${model}`.toLowerCase()),
        mobile: userMobile,
        otp,
        showTimer,
        mobileRef,
        otpRef,
        showOtpField,
        errorMessage,
        isCheckoutFlow,
        handler,
        handleMobile,
        handleOtp,
        onResendClick,
        onCompleteTimer,
        loginFor
    };

    const createAccountProps = {
        mobile,
        ...accountDetails,
        handleAccountDetails,
        nameValidator,
        validateEmail,
        checkout,
        carId
    };
    const renderImageIcon = () => {
        if (loginFor === SOURCES.WISHLIST || loginFor === SOURCES.ADD_TO_WISHLIST) {
            return <img src={IconWishlist} alt="wishlist" />;
        } else if (loginFor === SOURCES.START_PURCHASE) {
            return "";
        } else {
            return <img src={IconPerson} alt="" />;
        }
    };

    const renderTitle = () => {
        if (loginFor === SOURCES.WISHLIST) {
            return LOGIN_TITLE.WISHLIST;
        } else if (loginFor === SOURCES.MY_BOOKINGS) {
            return LOGIN_TITLE.MY_BOOKINGS;
        } else if (loginFor === SOURCES.ADD_TO_WISHLIST) {
            return LOGIN_TITLE.ADD_TO_WISHLIST;
        } else if (loginFor === SOURCES.START_PURCHASE) {
            return LOGIN_TITLE.startPurchase(capitalize(`${make} ${model}`.toLowerCase()));
        } else if (loginFor === SOURCES.MAKE_AN_OFFER) {
            return LOGIN_TITLE.makeOffer(capitalize(`${make} ${model}`.toLowerCase()));
        } else if (loginFor === SOURCES.MENU) {
            return DEFAULT_LOGIN_TITLE;
        } else if (saleActive) {
            return LOGIN_TITLE.ACTIVE_SALE;
        } else {
            return DEFAULT_LOGIN_TITLE;
        }
    };
    return (
        <React.Fragment>
            {isVisible && <div styleName={"styles.loginModal"}>
                <CloseButton type={"white"} styleName={"styles.closeButton"} onClickHandler={onClose} />
                <div styleName={"styles.loginModalRow"}>
                    <div styleName={"styles.rightPanel"}>
                        {saleActive ?
                        <figure styleName={"styles.saleWrap"} style={loginBannerInlineStyles}>
                            <img src={IconPattern} alt="" styleName={"styles.patternImage"}/>
                             <img src={ loginBannerImageUrl || SaleBanner} alt="" />
                             {loginTitle && <h3>{loginTitle}</h3>}
                        </figure>
                        : <figure>
                            <img src={BannerLogin} alt="" />
                        </figure>}
                    </div>
                    <div styleName={"styles.leftPanel"}>
                        <h4 styleName={"styles.verifyHeading"}>
                            {renderImageIcon()}
                            {renderTitle()}
                        </h4>
                        {isCheckoutFlow && activeStep === STEPS.LOGIN && <CheckoutCar />}
                        {activeStep === STEPS.LOGIN && <MobileVerify {...mobileVerifyProps} />}
                        {activeStep === STEPS.CREATE_ACCOUNT && <CreateAccount {...createAccountProps} />}
                        <div styleName={"styles.buttonWrap"}>
                            <div styleName={"styles.termsCheckGrid"}>
                                <Checkbox
                                    text="Send updates on"
                                    name="checkbox"
                                    onChange={onWhatsappToggle}
                                    checkedStatus={isWAConsent}
                                />
                                <p><img src={IconWhatsApp} alt="" />Whatsapp</p>
                            </div>
                            <div styleName={"styles.tandCAgreeText"}>
                                <p>By logging in, you agree to CARS24’s <a href={`${appUrl()}/terms-of-use/`} >Terms & Conditions</a></p>
                            </div>
                            <Button text={BTN_TEXT[activeStep]} onClick={handler} isLoading={isLoading} disabled={isBtnDisabled()} />
                        </div>
                    </div>

                </div>
            </div>}
        </React.Fragment>
    );
};
Login.propTypes = {
    mobile: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    secureToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    sendOTPConnect: PropTypes.func,
    verifyOtpConnect: PropTypes.func,
    setProfileWithTokenConnect: PropTypes.func,
    updateProfileConnect: PropTypes.func,
    onSuccess: PropTypes.func,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    isWAConsent: PropTypes.bool,
    isCheckoutFlow: PropTypes.bool,
    checkout: PropTypes.object,
    match: PropTypes.object,
    updateWAStatusConnect: PropTypes.func,
    updateUserConsentConnect: PropTypes.func,
    userProfileConnect: PropTypes.func,
    fetchUserConsentConnect: PropTypes.func,
    isSeller: PropTypes.bool,
    cityCode: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    loginFor: PropTypes.string,
    activeSaleConfig: PropTypes.object,
    updateUnlockCouponConnect: PropTypes.func
};
export default withRouter(Login);
